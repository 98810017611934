.resume-root {
  margin: 3rem 0 5rem 0;
  background-color: $offwhite;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
}

.resume {
  padding: 1rem;
  @include bp(md) {
    padding: 3rem;
  }
}
.resume-header {
  display: flex;
  flex-direction: column;
  @include bp(md) {
    justify-content: space-between;
    flex-direction: row;
  }
}

.resume-header-title {
  display: flex;
  align-items: flex-end;
  h2 {
    font-family: $font-stack-quaternary;
    font-size: 3.25rem;
    margin: 0 auto 1rem auto;
    @include bp(md) {
      font-size: 5rem;
      margin-bottom: 0;
    }
  }
}

.resume-header-info {
  display: grid;
  grid-template-columns: auto auto;
  @include bp(md) {
    display: initial;
  }
  p {
    font-size: 0.75rem;
    @include bp(md) {
      font-size: 0.9rem;
    }
  }
}

.resume-link,
.resume-link:hover {
  text-decoration: none;
  color: $black;
}

.resume-body {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  @include bp(md) {
    justify-content: space-between;
    flex-direction: row;
  }
}

.resume-subheader {
  margin-bottom: 2rem;
}

.resume-body-field {
  border-bottom: 1px $black solid;
  margin-bottom: 1rem;
}

.resume-body-main {
  @include bp(md) {
    margin-right: 2rem;
  }
}

.resume-body-side {
  width: 100%;
  @include bp(md) {
    width: 55%;
  }
}

.resume-body-side-skills {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 1rem;
  font-size: 0.75rem;
  @include bp(md) {
    grid-template-columns: auto;
    font-size: 0.9rem;
  }
}

.project-title {
  margin-bottom: 0;
}

.bold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.subtext {
  font-size: 0.9rem;
}

.resume-experience,
.resume-projects {
  margin-bottom: 2rem;
}

.aws-container {
  display: flex;
  margin-bottom: 1rem;
  @include bp(md) {
    margin-bottom: 0;
  }
}

.aws-badge {
  width: 85px;
  img {
    width: 100%;
  }
}

.aws-info {
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}
