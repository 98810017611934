@mixin flex-root {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
}

@mixin flex-end {
  display: flex;
  justify-content: flex-end;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin flex-column-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@mixin page-title {
  margin-bottom: 2rem;
  font-family: $font-stack-secondary;
}

@mixin bp($point) {
  @if $point == sm {
    @media only screen and (min-width: 421px) {
      @content;
    }
  } @else if $point == md {
    @media only screen and (min-width: 768px) {
      @content;
    }
  } @else if $point == lg {
    @media only screen and (min-width: 1024px) {
      @content;
    }
  } @else if $point == xl {
    @media only screen and (min-width: 1365px) {
      @content;
    }
  }
}
