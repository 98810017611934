.onlyHome {
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("/images/background-img/meandopie-sm.jpg");
  @include bp(md) {
    background-image: url("/images/background-img/meandopie-md.jpg");
  }
  @include bp(lg) {
    background-image: url("/images/background-img/meandopie-lg.jpg");
  }
  @include bp(xl) {
    background-image: url("/images/background-img/meandopie-xl.jpg");
  }
}

.app {
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  background-image: url("/images/background-img/handmadepaper.png");
}
