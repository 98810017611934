//colors
$white: #ffffff;
$clear: transparent;
$steelblue: #4965a4;
$seagreen: #377870;
$gray: #545d5a;
$black: #212529;
$offwhite: #fffaf9;
$opaque: rgba(255, 255, 255, 0.9);

//fonts
$font-stack-primary: "Raleway", sans-serif;
$font-stack-secondary: "Vidaloka", serif;
$font-stack-tertiary: "Petit Formal Script", cursive;
$font-stack-quaternary: "DM Serif Text", serif;
