footer {
  position: fixed;
  bottom: 0;
  height: 60px;
  line-height: 60px;
  width: 100%;
  background-color: $white;
  color: $black;
  font-size: 0.75rem;
  @include flex-center;
  @include bp(md) {
    font-size: 0.85rem;
  }
}
