.main-root {
  position: relative;
  width: 100vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: auto;

  @include bp(md) {
    height: 88vh;
  }
}

.section-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

//CSSTransition animation
.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
